<template>
  <div class="order-contaner">
    <!-- 我的设备列表 start -->
    <el-row class="list">
      <!-- 按钮 -->
      <!-- <el-row class="btnBox">
        <el-button
          icon="el-icon-plus"
          class="search"
          @click="assderVisible = true"
          >新增</el-button
        >
      </el-row> -->
      <!-- 当前设备 -->
      <el-row class="current-box" type="flex" align="middle"> </el-row>
      <!-- 表格展示 start -->
      <el-row class="table-box">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
        >
          <el-table-column prop="num" label="序号">
             <template slot-scope="{ row }">{{
              row.num || "-"
            }}</template></el-table-column
          >
          <el-table-column prop="comp" label="车间名称">
             <template slot-scope="{ row }">{{
              row.comp || "-"
            }}</template></el-table-column
          >
          <el-table-column prop="comp" label="添加">
             <template slot-scope="{ row }">{{
              row.comp || "-"
            }}</template></el-table-column
          >
          <el-table-column prop="handle" label="操作" width="218">
            <template slot-scope="scope">
              <!-- <span @click="handleEdit(scope.$index, scope.row)" class="see p">
                <router-link
                  :to="{ name: 'orderdetail', query: { id: scope.row.id } }"
                  >编辑</router-link
                >
              </span> -->
              <span
                @click="handleCancle1(scope.$index, scope.row)"
                class="see p"
              >
                删除
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <!-- 表格展示 end -->
      <!-- 分页开始 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listTotal"
        :page-size="pageSize"
        :current-page="page"
        @current-change="pageChange"
        @prev-click="prevChange"
        @next-click="nextChange"
      >
      </el-pagination>
      <!-- 分页结束 -->
    </el-row>
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="750px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="flex">
          <img
            src="@/assets/images/line2.png"
            @click="assderVisible = false"
            class="line2"
          />
          <span>新增</span>
        </p>

        <div>
          <el-button type="primary">提交</el-button>
          <el-button plain>关闭</el-button>
        </div>
      </span>

      <div>
        <!-- 表单开始 -->
        <el-form
          :model="ruleForm"
          :status-icon="false"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <!-- 详细地址: -->
          <el-form-item required prop="details" style="width: 90%">
            <p class="f14 c3 detail-contant">车间名称：</p>
            <el-input
              type="text"
              v-model="ruleForm.details"
              placeholder="请输入车间名称"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 详细地址:结束 -->
        </el-form>
        <!-- 表单结束 -->
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
export default {
  data() {
    var validateDetails = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入详细原因"));
      }
      callback();
    };
    //合同详情验证
    var validateIdDetail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入详细原因"));
      }
      callback();
    };
    var validateRemarkDetail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入备注内容"));
      }
      callback();
    };
    return {
      detailDialog: false, //详情对话框
      ruleForm: {
        reason: 0,
        details: "",
      },
      rules: { details: [{ validator: validateDetails, trigger: "blur" }] },
      assderVisible: false, //客服弹窗
      orderNum: "", //订单编号
      type: "", //所选设备类型
      mobel: "", //设备型号
      compName: "", //公司名称
      comps: [], //设备所有类型
      status: "", //所选订单状态
      statusArr: [], //订单所有状态
      tableData: [], //表格数据
      ruleDetailForm: {
        id: "",
        file: "", //合同附件
        remark: "该用户服务需要工程师进行驻场", //备注
      }, //合同详情弹框
      rulesDetail: {
        id: [{ validator: validateIdDetail, trigger: "blur" }],
        remark: [{ validator: validateRemarkDetail, trigger: "blur" }],
      },

      // 分页相关数据
      page: 1,
      pageSize: 5,
      listTotal: 0,

      cancleListArr: [],

      paramData: {}, //列表参数

      totalNum: 0, //设备总数

      // states: [],

      // 设备类型选择器
      props1: { value: "id", label: "typeName", children: "childrenList" },
      options1: [],
      posValue1: [],
      choseType: [],

      pdfFile: "",
      pdfUrl: "",
    };
  },

  watch: {
    // 订单状态改变
    status() {
      console.log("status改变了", this.status, typeof this.status);
      this.page = 1;
      this.paramData = {
        ...this.paramData,
        // orderNum: this.orderNum, //订单编号
        // deviceModel: this.mobel, //设备型号
        // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
        // companyName: this.compName, //公司名称
        status: Number(this.status), //订单状态
      };
      // console.log(this.paramData);
      // 报修订单-分页列表查询
      this.repairOrderList();
    },
    // 设备类型改变
    type() {
      // console.log(
      //   "status改变了",
      //   this.type,
      //   typeof this.type,
      //   "-----------------",
      //   Number(this.type)
      // );
      this.page = 1;
      if (this.type.length == 1) {
        this.paramData = {
          ...this.paramData,
          // orderNum: this.orderNum, //订单编号
          // deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          // deviceTypeId: this.type ? this.type : "",
          deviceTypeParentId: this.type[0],
          // companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
      } else {
        this.paramData = {
          ...this.paramData,
          // orderNum: this.orderNum, //订单编号
          // deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          // deviceTypeId: this.type ? this.type : "",
          deviceTypeParentId: this.type[0],
          deviceTypeId: this.type[1],
          // companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
      }

      // console.log(this.paramData);
      // 报修订单-分页列表查询
      this.repairOrderList();
    },
    // 订单编号改变
    orderNum() {
      if (this.orderNum != "") {
        // console.log("订单编号改变了并不为空");
        // 订单编号改变了并不为空
        this.page = 1;
        this.paramData = {
          ...this.paramData,
          orderNum: this.orderNum, //订单编号
          // deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          // companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
        // console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
      if (this.orderNum == "") {
        console.log("删除公司配置");
        delete this.paramData.orderNum;
        console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
    },
    // 设备型号改变
    mobel() {
      if (this.mobel != "") {
        // console.log(this.mobel, typeof this.mobel);
        // console.log("订单编号改变了并不为空");
        // 订单编号改变了并不为空
        this.page = 1;
        this.paramData = {
          ...this.paramData,
          // orderNum: this.orderNum, //订单编号
          deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          // companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
        // console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
      if (this.mobel == "") {
        console.log("删除公司配置");
        delete this.paramData.deviceModel;
        console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
    },
    // 公司名称改变
    compName() {
      if (this.compName != "") {
        // console.log("订单编号改变了并不为空");
        // 订单编号改变了并不为空
        this.page = 1;
        this.paramData = {
          ...this.paramData,
          // orderNum: this.orderNum, //订单编号
          // deviceModel: this.mobel, //设备型号
          // deviceTypeId: Number(this.type) ? Number(this.type) : "", //设备类型
          companyName: this.compName, //公司名称
          // status: Number(this.status) ? Number(this.status) : "", //订单状态
        };
        // console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
      if (this.compName == "") {
        console.log("删除公司配置");
        delete this.paramData.companyName;
        console.log(this.paramData);
        // 报修订单-分页列表查询
        this.repairOrderList();
      }
    },
  },

  mounted() {},

  created() {
    this.paramData = { pageNo: this.page, pageSize: this.pageSize };

    // 报修订单-分页列表查询
    this.repairOrderList();

    // 取消原因-分页列表查询
    this.cancleList();

    // 查询全部设备类型
    this.getAllType();

    // 状态分类统计
    this.getAllState();

    // 状态分类统计状态
    // this.getAllState();
  },

  methods: {
    handleConfirm1(index, row) {
      console.log("aaaaaaaaaaaaaa");

      this.$confirm("确定已执行吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "确定执行成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleCancle1(index, row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "未删除",
          });
        });
    },
    // 设备类型改变
    posHandle1(a) {
      console.log(a, "设备类型");
      this.type = a;
      // this.choseType = a;
      // this.ruleForm.deviceTypeId = a.join(",");
      // console.log(this.ruleForm.deviceTypeId);
    },
    // 状态分类统计状态
    // getAllState() {
    //   this.$API
    //     .getAllState()
    //     .then((res) => {
    //       if (res.code == 1) {
    //         res.data.forEach((item) => {
    //           // this.states.push({ value: item.statusName, id: item.status });
    //           // if (item.status == 0) {
    //           //   this.startNum = item.num;
    //           // }
    //           // if (item.status == 1) {
    //           //   this.stopNum = item.num;
    //           // }
    //           // if (item.status == 2) {
    //           //   this.repairNum = item.num;
    //           // }
    //           this.totalNum += item.num;
    //           console.log(this.totalNum);
    //         });
    //       } else {
    //         this.$message.info(res.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // 状态分类统计
    getAllState() {
      this.$API
        .getAllOrderState()
        .then((res) => {
          // this.statusArr = res.result.map((item) => {
          //   if (item.value == "1") {
          //     item.text = "待指派";
          //     item.title = "待指派";
          //   }
          //   return item;
          // });
          if (res.code == 1) {
            this.statusArr = res.data.map((item) => {
              // if (item.value == "1") {
              //   console.log("===========");
              //   item.text = "待指派";
              //   item.title = "待指派";
              // }
              // if (item.value == "3") {
              //   console.log("===========");
              //   item.text = "服务中";
              //   item.title = "服务中";
              // }
              return item;
            });
            // res.data.forEach((item) => {
            // this.states.push({ value: item.statusName, id: item.status });
            // if (item.status == 0) {
            //   this.startNum = item.num;
            // }
            // if (item.status == 1) {
            //   this.stopNum = item.num;
            // }
            // if (item.status == 2) {
            //   this.repairNum = item.num;
            // }
            // this.totalNum += item.num;
            // });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // 状态分类统计
      this.$API
        .getStatusNum()
        .then((res) => {
          this.states = res.result;
          if (res.code == 1) {
            res.data.forEach((item) => {
              if (item.status == 0) {
                this.startNum = item.num;
              }
              if (item.status == 1) {
                this.stopNum = item.num;
              }
              if (item.status == 2) {
                this.repairNum = item.num;
              }
              this.totalNum += item.num;
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // this.$API
      //   .getAllState()
      //   .then((res) => {
      //     if (res.code == 1) {
      //       this.statusArr = res.data;
      //       res.data.forEach((item) => {
      //         // this.states.push({ value: item.statusName, id: item.status });
      //         // if (item.status == 0) {
      //         //   this.startNum = item.num;
      //         // }
      //         // if (item.status == 1) {
      //         //   this.stopNum = item.num;
      //         // }
      //         // if (item.status == 2) {
      //         //   this.repairNum = item.num;
      //         // }
      //         this.totalNum += item.num;
      //       });
      //     } else {
      //       this.$message.info(res.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    // 查询全部设备类型
    getAllType() {
      this.$API
        .getAllType()
        .then((res) => {
          if (res.code == 1) {
            // this.comps = res.data;
            this.options1 = res.data.map((item) => {
              // 第一级，市
              // item.areaTrees 第二级，区
              item.childrenList = item.childrenList.map((secondItem) => {
                // secondItem
                // secondItem.childrenList = secondItem.childrenList.map(
                //   (thirdItem) => {
                //     delete thirdItem.childrenList;
                //     return thirdItem;
                //   }
                // );
                delete secondItem.childrenList;
                return secondItem;
              });

              return item;
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消原因-分页列表查询
    cancleList() {
      this.$API
        .cancleList()
        .then((res) => {
          if (res.code == 1) {
            this.cancleListArr = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 报修订单-分页列表查询
    repairOrderList() {
      this.$API
        .repairOrderList(this.paramData)
        .then((res) => {
          //   num: "001",
          // comp: "白白有限公司",
          // modal: "XCDX-199",
          // brand: "西门子",
          // status: "服务中",
          // date: "2021/10/10",
          // handle: "2",
          if (res.code == 1) {
            this.listTotal = Number(res.data.total);
            this.tableData = [];
            res.data.records.forEach((item, index) => {
              this.tableData.push({
                num: item.orderNum, //订单编号
                comp: item.companyName, //公司
                modal: item.deviceModel, //设备型号
                brand: item.brandName, //设备品牌
                status: item.statusName, //订单状态
                date: item.createTime, //下单时间
                id: item.id,
                orderStatus: item.orderStatus, //订单状态值
                contractMoney: item.contractMoney, //合同金额
                workOrderCount: item.workOrderCount, //维保记录数量  为0不显示维保记录   不为零显示维保记录
                contractFileUrl: item.contractFileUrl,
                contractMoney: item.contractMoney,
                contractRemarks: item.contractRemarks,
                deviceId: item.deviceId,
              });
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //对话框处理函数
    handleDetailClose() {
      this.detailDialog = false;
    },
    // 取消
    cancle() {
      this.assderVisible = false;
      this.ruleForm.reason = 0;
      this.ruleForm.details = "";
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let paramData = {
            cancelId: this.cancleListArr[this.ruleForm.reason].id, //取消id
            cancelExplain: this.ruleForm.details, //取消说明
            id: Number(this.id),
            orderStatus: 99,
          };
          console.log(paramData, "paramdata");
          this.$alert("确定要取消吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showCancelButton: true,
            callback: (action) => {
              // 确定  confirm  取消 cancel
              if (action == "confirm") {
                this.$API
                  .cancleRepairOrder(paramData)
                  .then((res) => {
                    if (res.code == 1) {
                      this.$message({
                        message: "取消成功",
                        type: "success",
                      });
                      // 报修订单-分页列表查询
                      this.repairOrderList();
                      setTimeout(() => {
                        this.assderVisible = false;
                      }, 500);
                    } else {
                      this.$message.info(res.msg);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            },
          });

          // alert("submit!");
        } else {
          // console.log("error submit!!");
          // return false;
        }
      });
    },
    // 改变性别
    changeSex(index) {
      this.ruleForm.reason = index;
    },
    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    // 维保记录
    handleWeibao(index, item) {
      // console.log(item);
      this.$router.push({ name: "repairrecord", query: { id: item.deviceId } });
    },
    // 合同详情
    handleDetails(index, row) {
      console.log(row, "row");
      this.ruleDetailForm.id = row.contractMoney;
      let fileArr = row.contractFileUrl.split("/");
      this.pdfFile = fileArr[fileArr.length - 1];
      this.pdfUrl = row.contractFileUrl;
      this.ruleDetailForm.remark = row.contractRemarks;
    },

    // 查看详情
    handleEdit(index, row) {},
    // 取消订单
    handleCancle(index, row) {
      // console.log(row, "row");
      this.id = row.id;
    },
    // 评价
    handleCommit(index, row) {},
    // 当前页码改变
    pageChange(page) {
      this.page = page;
      console.log("page", this.page);
      this.paramData = {
        ...this.paramData,
        pageNo: this.page,
      };
      this.repairOrderList();
    },

    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // 导出处理
    exportHandle(fileName) {
      let paramData = {};
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      let param = { ...this.queryParam };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        param["selections"] = this.selectedRowKeys.join(",");
      }
      console.log("导出参数", paramData);
      this.$API.exportOrder(paramData).then((data) => {
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(
            new Blob([data], { type: "text/csv,charset=UTF-8" }),
            fileName + ".xls"
          );
        } else {
          let url = window.URL.createObjectURL(
            new Blob([data], { type: "text/csv,charset=UTF-8" })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
        // 组装a标签
        let elink = document.createElement("a");
        // 设置下载文件名
        elink.download = "支出汇总表.xls";
        elink.style.display = "none";
        //  type: "text/csv,charset=utf-8",
        // let blob = new Blob([data], {
        //   type: "text/csv,charset=utf-8",
        // });
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
        window.URL.revokeObjectURL(blob);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btnBox {
  // margin-top: 20px;
  text-align: right;
}
.order-contaner {
  box-sizing: border-box;
  padding: 32px;
  // 设备列表
  .list {
    .title {
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
  }
  // 订单编号
  .input {
    display: inline-block;
    margin-right: 32px;
    span {
      font-size: 14px;
      color: #333333;
      margin-right: 20px;
    }
    .el-input {
      width: 160px;
      height: 40px;
      margin-left: 20px;
    }
  }
  .compName {
    margin-right: 0px;
  }
  // 选择公司
  .check {
    margin-top: 32px;
    margin-left: 32px;
    display: inline-block;
  }
  .company {
    .name {
      margin-right: 20px;
    }
    // .el-select {
    //   width: 160px;
    //   margin-left: 10px;
    // }
    .el-cascader {
      width: 160px;
      margin-left: 10px;
    }
  }
  //选择订单状态
  .order-box {
    margin-top: 32px;
    margin-left: 32px;
    display: inline-block;
  }
  .order {
    .name {
      margin-right: 20px;
    }
    .el-select {
      width: 160px;
      margin-left: 10px;
    }
  }
  // 按钮
  .search {
    // width: 94px;
    width: auto;
    height: 40px;
    color: #ffffff;
    line-height: 7px !important;
    background: #ed1b23;
    border-radius: 4px 4px 4px 4px;
    margin-right: 30px;
  }
  //   当前设备
  .current-box {
    margin-top: 20px;
    .num {
      color: #999999;
      margin-bottom: 10px;
      span {
        color: #1577fc;
      }
    }
  }
  // 表格
  .table-box {
    .see {
      margin: 5px;
      color: #007bd9;
    }
    .cancle {
      margin: 5px;
      color: #ff0015;
    }
    .commit {
      margin: 5px;
      color: #4aa800;
    }
    margin-bottom: 48px;
    .handle {
      ul {
        padding: 0 20px;
        display: flex;
        align-items: center;
        text-align: center;
      }
      li {
        // float: left;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #007bd9;
        line-height: 22px;
        margin-right: 16px;
        &.cancle {
          color: #ff0015;
        }
        &.assess {
          color: #4aa800;
        }
      }
    }
  }
  // 分頁
  .el-pagination {
    text-align: right;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }
  // 自定义弹窗样式
  .assderTc {
    // display: flex;
    // align-items: center;
    // .title {
    //   padding-top: 32px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   position: relative;
    //   font-size: 22px;
    //   font-weight: bold;
    //   color: #333333;
    //   img {
    //     width: 32px;
    //     height: 32px;
    //     position: absolute;
    //     top: 32px;
    //     right: 32px;
    //   }
    // }
    // ::v-deep .el-dialog__header {
    //   padding: 0;
    // }
    // ::v-deep .el-dialog {
    //   border-radius: 0 !important;
    // }
    // ::v-deep .el-dialog__body {
    //   padding: 0;
    // }
    // ::v-deep .el-dialog__footer {
    //   padding: 0;
    // }
    // ::v-deep .el-dialog {
    //   border-radius: 10px;
    // }
    // .ja-c {
    // }

    // .btnad {
    //   width: 112px;
    //   height: 40px;
    //   background: rgba(246, 247, 249, 1);
    //   border: 1px solid #ecedef;
    //   opacity: 1;
    //   border-radius: 20px;
    // }
    // .btnsad {
    //   color: #333;
    //   width: 112px;
    //   height: 40px;
    //   background: rgba(255, 195, 44, 1);
    //   opacity: 1;
    //   border-radius: 20px;
    // }
  }

  .camera {
    white-space: nowrap;
  }
  .el-form {
    padding: 0px !important;
  }
  .el-form-item__content {
    line-height: normal;
  }

  .el-dialog__body {
    height: 250px;
    padding: 0px;
  }
  .el-dialog__header {
    background: #f9f9f9;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e2e2e2;
    padding: 0px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-right: 32px;
    padding-left: 32px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      font-size: 22px;
      img {
        width: 32px;
        height: 32px;
      }

      .line2 {
        width: 4px;
        height: 14px;
        margin-right: 10px;
      }
    }
  }
  .el-form-item {
    margin-bottom: 0px;
  }
  .demo-ruleForm {
    margin-top: 16px;
    margin-left: 32px;
    margin-bottom: 100px;
    // 性別
    .item {
      margin-top: 24px;
      // display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      color: #333333;
      font-size: 14px;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .detail-contant {
      margin-top: 20px;
      margin-bottom: 16px;
    }
  }
  .el-textarea__inner {
    width: 686px;
    height: 128px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #999999;
  }
  .btn {
    margin-top: 56px;
    .cancle {
      width: 214px;
      height: 50px;
      background: rgba(255, 0, 21, 0.13);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #ff0e1e;
      margin-left: 105px;
      color: #ff0015;
      font-size: 16px;
    }
    .submit {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      margin-left: 48px;
      color: #ffffff;
      font-size: 16px;
    }
  }
  // 详情对话框
  .detail-dialog-box {
    box-sizing: border-box;
    padding: 32px;
    .form-box {
      // 性別
      .item {
        display: inline-block;
        margin-left: 20px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .item2 {
        margin-left: 56px;
      }
      .file-img {
        width: 94px;
        height: 94px;
        margin-right: 10px;
      }
    }
    .title {
      text-align: center;
      padding-left: 345px;

      p {
        color: #333333;
        font-size: 22px;
      }
      img {
        width: 32px;
        height: 32px;
        // position: relative;
        // top: 8px;
        // right: -300px;
      }
    }
    //对话框垂直居中
    top: 50% !important;
    transform: translateY(-50%);
    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }
    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }
    //input表单样式
    .el-input__inner {
      width: 320px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 20px;
    }
    //label样式
    .el-form-item__label {
      text-align: left;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 536px;
      height: 104px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
    }
  }
}
</style>
